
import * as aroraProviderRuntime$_5x3WaWSkn0zRj2wGPEeyAq4kFf5D1xak5DdP1p9Yd44 from 'D:/deploy/UploadHidden/20250331-P903.315/arora-front/satellite/build-rest-e49f7650-51d8-464f-a499-aaf6cbb7f230/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$_5x3WaWSkn0zRj2wGPEeyAq4kFf5D1xak5DdP1p9Yd44, defaults: {} }
}
        